import React from 'react';
import { Link } from 'react-router-dom';
import { Mail } from 'lucide-react';
import Header from './Header';
import Footer from './Footer';
import styles from './PrivacyPolicy.module.css';

const Contact = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      <Header/>
      <main className={`max-w-5xl mx-auto px-4 py-8 ${styles.container}`}>
        <section className="bg-white shadow-md rounded-lg p-6 mb-6">

          <h2>Contact Us</h2>

          <h4>Questions or Feedback?</h4>
		  <p>Do you have questions, general feedback or other inquiries?<br></br>You can reach us directly at:<br></br><br></br>
          </p>

          <a 
            href="mailto:support@finiteaudio.com" 
            className="flex items-center"
          >
            <Mail className="text-black mr-2" size={20} />
            support@finiteaudio.com
          </a>

		  <h4>Full Address</h4>
		  <p>Finite Audio UG (haftungsbeschr&auml;nkt)<br></br>
             Auf dem Eichelkamp 27<br></br>
             32052 Herford<br></br>
             Germany
          </p>     

        </section>

      </main>



    </div>
  );
};

export default Contact;