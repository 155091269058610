import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="py-8 bg-gray-800">
      <div className="max-w-6xl mx-auto px-8 flex flex-col items-center">
        <div className="text-4xl font-bold text-white mb-6">
          <Link to="/" >FiniteAudio</Link>          
        </div>
        <nav className="mb-4 w-full">
          <ul className="flex flex-wrap justify-center gap-4">
            <li><Link to="/privacy-policy" className="text-sm text-gray-400 hover:text-yellow-400 transition-colors">Privacy Policy</Link></li>
            <li><Link to="/terms-of-service" className="text-sm text-gray-400 hover:text-yellow-400 transition-colors">Terms of Service</Link></li>
            <li><Link to="/refund-policy" className="text-sm text-gray-400 hover:text-yellow-400 transition-colors">Refund Policy</Link></li>
            <li><Link to="/contact" className="text-sm text-gray-400 hover:text-yellow-400 transition-colors">Contact Us</Link></li>
            <li><Link to="/impressum" className="text-sm text-gray-400 hover:text-yellow-400 transition-colors">Legal Notice / Impressum</Link></li>
          </ul>
        </nav>
        <p className="text-sm text-gray-400">© 2024 FiniteAudio. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;