import FastSpringScript from './FastSpringScript';
import BuyDisturiaButton from './BuyDisturiaButton';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Sliders, Music, Filter, Volume2, X, Menu, AudioWaveform, LayoutGrid } from 'lucide-react';
import AudioPlayer from './AudioPlayer';

// Modal Component (unchanged)
const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-80 z-50 flex justify-center items-center p-4"
      onClick={onClose}
    >
      <div 
        className="relative"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          onClick={onClose}
          className="absolute -top-5 -right-12 text-white hover:text-yellow-400 z-10 rounded-full p-2"
          aria-label="Close modal"
        >
          <X size={24} />
        </button>
        {children}
      </div>
    </div>
  );
};

const DisturiaHomepage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  
  return (
    <div className="min-h-screen bg-gray-900 text-white">
      <FastSpringScript />
      <header className="bg-gray-700 py-4 px-8 mb-16">
        <div className="max-w-6xl mx-auto flex justify-between items-center">
          <div className="text-3xl font-bold text-white">
            <Link to="/" className="text-yellow-400 hover:text-yellow-400 transition-colors">FiniteAudio</Link>
          </div>
          <div className="flex items-center space-x-4">
            <nav className="hidden md:flex items-center">
              <ul className="flex space-x-6 items-center">
                <li><a href="#audio-samples" className="hover:text-yellow-500 transition-colors">Listen</a></li>
                <li><a href="#features" className="hover:text-yellow-500 transition-colors">Features</a></li>
                <li><a href="#system-requirements" className="hover:text-yellow-500 transition-colors">Requirements</a></li>
              </ul>
            </nav>
            <BuyDisturiaButton className="w-16 h-16 md:w-auto md:h-auto md:px-4 md:py-2 bg-yellow-400 text-gray-900 rounded-full font-bold hover:bg-yellow-500 transition-colors flex flex-col md:flex-row items-center justify-center">
            <span className="text-sm md:text-base lg:text-base">Buy</span>
            <span className="text-sm md:text-base lg:text-base md:ml-1">Now</span>
            </BuyDisturiaButton>        
            <button 
              className="md:hidden text-white hover:text-yellow-400 transition-colors"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              aria-label="Toggle menu"
            >
              <Menu size={24} />
            </button>
          </div>
        </div>
      </header>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-90 z-50 md:hidden">
          <div className="flex flex-col items-center justify-center h-full">
            <button 
              className="absolute top-4 right-4 text-white hover:text-yellow-400 transition-colors"
              onClick={() => setIsMenuOpen(false)}
              aria-label="Close menu"
            >
              <X size={24} />
            </button>
            <ul className="text-center space-y-6">
              <li><a href="#audio-samples" className="text-2xl hover:text-yellow-500 transition-colors" onClick={() => setIsMenuOpen(false)}>Listen</a></li>
              <li><a href="#features" className="text-2xl hover:text-yellow-500 transition-colors" onClick={() => setIsMenuOpen(false)}>Features</a></li>
              <li><a href="#system-requirements" className="text-2xl hover:text-yellow-500 transition-colors" onClick={() => setIsMenuOpen(false)}>Requirements</a></li>
            </ul>
          </div>
        </div>
      )}

      <main>
        <section className="max-w-6xl mx-auto px-8">
          <div className="flex flex-col md:flex-row items-center pt-6 pb-8">          
            <div className="w-full md:w-1/2 pr-0 md:pr-8 mb-8 md:mb-0 text-center md:text-left">            
              <h1 className="text-5xl font-bold mb-6">
                Disturia
              </h1>
              <p className="text-xl mb-3">
                Cutting-Edge Distortion Plugin
              </p>
              <p className="text-xl mb-10">
                VST3 | AU | VST 
              </p>              
              <div className="relative inline-block">
                <BuyDisturiaButton className="px-8 py-3 bg-yellow-400 text-gray-900 rounded-full font-bold text-xl hover:bg-yellow-500 transition-colors" text="Get Disturia $30"/>
                <Link to="/download-disturia" className="text-lg text-yellow-400 absolute left-1/3 transform -translate-x-1/3 top-full mt-5 hover:underline">Try Free Demo</Link>
              </div>
            </div>
            <div className="w-full md:w-1/2 pt-8 md:pt-0">            
              <img 
                src="/img/Disturia_Screenshot.png" 
                alt="Disturia Plugin Interface" 
                className="rounded-lg shadow-lg w-full max-w-2xl mx-auto cursor-pointer hover:opacity-90 transition-opacity"
                onClick={() => setIsModalOpen(true)}
              />            
            </div>
          </div>
        </section>  

        <section id="audio-samples" className="bg-gray-100 text-black py-16 my-16 w-full">
          <div className="max-w-5xl mx-auto px-8">
            <h2 className="text-4xl font-bold mb-8 text-center">Hear the Difference</h2>
            <p className="text-xl mb-16 max-w-3xl mx-auto">
              Experience the FiniteAudio Disturia distortion plugin yourself!
              Below, you'll find pairs of audio samples: one processed with Disturia and the original, unprocessed version. 
              Compare them to hear how Disturia enhances and transforms your sound. 
            </p>            
            <div className="grid md:grid-cols-2 gap-8 mb-12">
              <AudioPlayer 
                original="/audio/FiniteAudio_Disturia_SynthLoop_NoDist_JP.mp3" 
                processed="/audio/FiniteAudio_Disturia_SynthLoop_Dist_JP.mp3"
                label="Synth Lead"
                composer="Jan Pfitzer"
                url="https://janpfitzer.com"
              />
              <AudioPlayer 
                original="/audio/FiniteAudio_Disturia_Guitar_NoDist_MS.mp3" 
                processed="/audio/FiniteAudio_Disturia_Guitar_Dist_MS.mp3"
                label="Guitar Riff"
                composer="Martin Schuppius"
                url=""                
              />
              <AudioPlayer 
                original="/audio/FiniteAudio_Disturia_BassLoop_NoDist_JP.mp3" 
                processed="/audio/FiniteAudio_Disturia_BassLoop_Dist_JP.mp3"
                label="Bass Line"
                composer="Jan Pfitzer"
                url="https://janpfitzer.com"                
              />
              <AudioPlayer 
                original="/audio/FiniteAudio_Disturia_ElecDrumLoop_NoDist_JP.mp3" 
                processed="/audio/FiniteAudio_Disturia_ElecDrumLoop_Dist_JP.mp3"
                label="Drum Loop"
                composer="Jan Pfitzer"
                url="https://janpfitzer.com"                
              />
            </div>
            <div className="flex justify-center">
              <div className="w-full md:w-2/4 lg:w-7/12">
                <AudioPlayer 
                  original="/audio/FiniteAudio_NO Disturia_DemoTrack_JanPfitzer_v03_mastered.mp3" 
                  processed="/audio/FiniteAudioDisturia_DemoTrack_JanPfitzer_v03_mastered.mp3" 
                  label="Full Demo Track"
                  composer="Jan Pfitzer"
                  url="https://janpfitzer.com"                
                />
              </div>
            </div>
          </div>
        </section>



        <section id="features" className="max-w-5xl mx-auto px-8">
        <div >
          <h2 className="text-5xl font-bold mb-8 text-center">
            Unleash Creative Distortion with Disturia
          </h2>
          <p className="text-xl mb-4 max-w-2xl mx-auto">
            Shape your sound with precision using Disturia's intuitive controls and powerful distortion engine.
          </p>
          <p className="text-xl mb-8 max-w-2xl mx-auto">
            FiniteAudio Disturia features four different distortion algorithms, high-pass and low-pass filters, a varied preset library, auto gain, oversampling and much more.
          </p>          

          <div className="bg-gray-800 p-8 rounded-lg shadow-lg max-w-4xl mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
              <div className="flex items-center">
                <AudioWaveform className="text-yellow-400 mr-4 flex-shrink-0" size={48} />
                <div className="text-left">
                  <h3 className="text-2xl font-bold">Multiple Distortion Algorithms</h3>
                  <p>Four different distortion algorithms, with individual parameters each</p>
                </div>
              </div>
              <div className="flex items-center">
                <Filter className="text-yellow-400 mr-4 flex-shrink-0" size={48} />
                <div className="text-left">
                  <h3 className="text-2xl font-bold">Advanced Filtering</h3>
                  <p>Shape your tone with High-Pass and Low-Pass filters</p>
                </div>
              </div>
              <div className="flex items-center">
                <Music className="text-yellow-400 mr-4 flex-shrink-0" size={48} />
                <div className="text-left">
                  <h3 className="text-2xl font-bold">Preset Library</h3>
                  <p>Choose from a variety of presets and create your own ones</p>
                </div>
              </div>
              <div className="flex items-center">
                <Volume2 className="text-yellow-400 mr-4 flex-shrink-0" size={48} />
                <div className="text-left">
                  <h3 className="text-2xl font-bold">Auto Gain</h3>
                  <p>Maintain consistent output levels automatically</p>
                </div>
              </div>
              <div className="flex items-center">
                <Sliders className="text-yellow-400 mr-4 flex-shrink-0" size={48} />
                <div className="text-left">
                  <h3 className="text-2xl font-bold">Fine-Tuned Control</h3>
                  <p>Adjust Boost, Volume and more with ease</p>
                </div>
              </div>              
              <div className="flex items-center">
                <LayoutGrid className="text-yellow-400 mr-4 flex-shrink-0" size={48} />
                <div className="text-left">
                  <h3 className="text-2xl font-bold">Oversampling</h3>
                  <p>Improve the sound by running at a higher internal sample rate</p>
                </div>
              </div>              
            </div>
          </div>
        </div>
      </section>      

        <section id="system-requirements" className="bg-gray-100 py-16 mt-16">
          <div className="max-w-5xl mx-auto px-8">
            <h2 className="text-4xl font-bold mb-12 text-center text-gray-800">System Requirements</h2>
            <p className="text-xl mb-8 max-w-3xl mx-auto text-gray-800">
              FiniteAudio Disturia is an audio plugin. In order to run it you need a Digital Audio Workstation (DAW). 
              Disturia is compatible with all major DAWs, except Pro Tools. 
              If you want to make sure Disturia is compatible with your DAW, please try the <Link to="/download-disturia" className="text-blue-600 hover:text-blue-900 hover:underline">free demo version</Link>. 
            </p>               
            <div className="grid md:grid-cols-2 gap-8">
              <div className="bg-white p-8 rounded-lg shadow-md flex flex-col items-center text-center">
                <h3 className="text-2xl font-semibold text-gray-800 mb-4">Windows</h3>
                <p className="text-xl mb-4 text-gray-800">Windows 10+</p>
                <p className="text-gray-600">VST2 64-bit, VST3 64-bit</p>
              </div>
              <div className="bg-white p-8 rounded-lg shadow-md flex flex-col items-center text-center">
                <h3 className="text-2xl font-semibold text-gray-800 mb-4">Mac</h3>
                <p className="text-xl mb-4 text-gray-800">macOS 10.14+, Intel and Apple Silicon</p>
                <p className="text-gray-600">AU 64-bit, VST2 64-bit, VST3 64-bit</p>
              </div>
            </div>
          </div>
        </section>

        <section id="features2" className="max-w-5xl mx-auto px-8">
          <div className="text-center">
            <div className="mt-16">
              <h2 className="text-3xl font-bold mb-4">Ready to elevate your sound?</h2>
              <BuyDisturiaButton className="mb-16 px-8 py-3 bg-yellow-400 text-gray-900 rounded-full font-bold text-xl hover:bg-yellow-500 transition-colors" text = "Get Disturia Now">
              </BuyDisturiaButton>
            </div>
          </div>
        </section>        

      </main>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <img 
          src="/img/Disturia_Screenshot.png" 
          alt="Disturia Plugin Interface"
          className="max-w-full max-h-[85vh] w-auto h-auto object-contain shadow-xl"
        />
      </Modal>

    </div>
  );
};

export default DisturiaHomepage;