import React from 'react';

const BuyDisturiaButton = ({ className, text = "Buy Now" }) => {
    const handleClick = (e) => {
        e.preventDefault();
        console.log('Button clicked');
        if (window.fastspring) {
          console.log('Adding product to cart');
          window.fastspring.builder.add('disturia-distortion-plugin');
          console.log('Calling FastSpring checkout');
          window.fastspring.builder.checkout();
        } else {
          console.error('FastSpring object is not available');
        }
      };
    
      return (
        <button
          className={className}
          onClick={handleClick}
        >
          {text}
        </button>
      );
    };

export default BuyDisturiaButton;