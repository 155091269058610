import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import Footer from './Footer';
import DisturiaHomepage from './DisturiaHomepage';
import PrivacyPolicy from './PrivacyPolicy';
import RefundPolicy from './RefundPolicy';
import Datenschutz from './Datenschutz';
import TermsOfService from "./TermsOfService";
import Impressum from './Impressum';
import Contact from './Contact';
import DownloadDisturia from './DownloadDisturia';

function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <ScrollToTop />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<DisturiaHomepage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/datenschutz" element={<Datenschutz />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/refund-policy" element={<RefundPolicy />} />
            <Route path="/impressum" element={<Impressum />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/download-disturia" element={<DownloadDisturia />} />            
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;