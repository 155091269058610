import React from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import styles from './PrivacyPolicy.module.css';

const TermsOfService = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      <Header/>
      <main className={`max-w-5xl mx-auto px-4 py-8 ${styles.container}`}>
        <section className="bg-white shadow-md rounded-lg p-6 mb-6">

            <h2>General Note</h2>

            <p >Our order process is conducted by our online reseller FastSpring.com. 
            FastSpring.com is the Merchant of Record for all our orders.</p>  

            <h2>End User License Agreement</h2>

            <p >Finite Audio UG, Auf dem Eichelkamp 27, 32052 Herford (“Licensor”) grants you a licence to use the software Disturia-plugin (“Licensed Product”) subject to the terms and conditions of this End User License Agreement („Agreement“). </p>  

            <h3>1. Applicability</h3>

            <ul>
                <li>
                    <h3>1.1</h3>
                    <p >By downloading and executing the Licensed Product you indicate that you agree to be bound by all the terms and conditions of this Agreement and that you accept this Agreement.</p>
                </li>
                <li>
                    <h3>1.2</h3>
                    <p >Deviating general terms and conditions shall not apply unless the Licensor expressly agrees to their validity at least in text form.
                    </p>
                </li>
            </ul>        


            <h3>2. Licensed Product</h3>

            <ul>
                <li>
                    <h3>2.1</h3>
                    <p >The Licensed Product is an audio-plugin for the modification of audio material with distortion effects.
                    </p>
                </li>
                <li>
                    <h3>2.2</h3>
                    <p >You can access, download and install the Licensed Product independently after confirming these Agreement.
                    </p>
                </li>
                <li>
                    <h3>2.3</h3>
                    <p >If the Licensor provides you with new program versions (updates, patches, bug-fixes), these are also subject to this Agreement, unless they are the subject of a separate agreement.
                    </p>
                </li>                
            </ul>   


            <h3>3. Technical requirements</h3>
            <ul>
                <li>
                    <h3>3.1</h3>
                    <p >The Licensed Product is provided as an audio plugin that can only be used by integrating it into the digital audio workstation (“DAW”).
                    </p>
                </li>
                <li>
                    <h3>3.2</h3>
                    <p >You acknowledge that it is your responsibility to check wheatear you meet the technical requirements and for the integration of the Licensed Product into your DAW.
                    </p>
                </li>              
            </ul>  

            <h3>4. Scope of License</h3>

            <ul>
                <li>
                    <h3>4.1</h3>
                    <p >Upon full payment of the licence fee and upon acceptance of the terms and conditions of this Agreement the Licensor grants you a non-exclusive, perpetual right to use the Licensed Product as intended for the modification of audio materials. The licensed use includes the integration of the Licensed Product into the DAW. You are not entitled to rent or sublicense the Licensed Product in any way.
                    </p>
                </li>
                <li>
                    <h3>4.2</h3>
                    <p >Apart from that, you are only entitled to decompile and reproduce the Licensed Product if this is provided for by law (Sections 69d, 69e of the German Copyright Act). 
                    </p>
                </li>
                <li>
                    <h3>4.3</h3>
                    <p >You are entitled to permanently transfer the purchased copy of the Licensed Product to a third party. In this case, you will completely cease using the Licensed Product, remove all installed copies of the Licensed Product from your computer and delete all copies on other data carriers or hand them over to the Licensor, unless you are legally obliged to keep them for a longer period of time. Upon request, you will confirm to the Licensor in writing that the aforementioned measures have been carried out in full or, if necessary, explain the reasons for a longer retention period. Furthermore, you shall expressly agree with the third party to respect the scope of the granting of rights in accordance with this Section 3.
                    </p>
                </li>     
                <li>
                    <h3>4.4</h3>
                    <p >If you use the Licensed Product to an extent that exceeds the acquired rights of use qualitatively (with regard to the type of use permitted) or quantitatively (with regard to the number of licenses acquired), you shall immediately acquire the rights of use necessary for the permitted use. If you fail to do so, the Licensor will assert the rights to which he is entitled. 
                    </p>
                </li> 
                <li>
                    <h3>4.5</h3>
                    <p >Copyright notices, serial numbers and other features serving to identify the program may not be removed from the Licensed Product or altered.
                    </p>
                </li>                                            
            </ul>              

            <h3>5. Intellectual Property Rights
            </h3>
            <ul>
                <li>
                    <h3>5.1</h3>
                    <p >All intellectual property rights, including copyrights and industrial property rights to the Licensed Product and any accompanying material, are the exclusive property of the Licensor in relation to you. The Licensor reserves all rights that have not been expressly transferred to you in this agreement.
                    </p>
                </li>
                <li>
                    <h3>5.2</h3>
                    <p >In relation to the Licensor, you retain all property rights including copyrights and industrial property rights to your data processed with the Licensed Product.
                    </p>
                </li>              
            </ul>             

            <h3>6. Intellectual Property Rights
            </h3>
            <ul>
                <li>
                    <h3>6.1</h3>
                    <p >The Licensor does not guarantee that the Licensed Product corresponds to your requirements.
                    </p>
                </li>
                <li>
                    <h3>6.2</h3>
                    <p >You and the Licensor are liable in accordance with the generally applicable legal provisions unless no further restriction results from the following provisions.
                    </p>
                </li>   
                <li>
                    <h3>6.3</h3>
                    <p >If the Licensed Product is used in a free version, the Licensor is only liable for intent and gross negligence; any liability for slight negligence is excluded.
                    </p>
                </li>  
                <li>
                    <h3>6.4</h3>
                    <p >In the case of use of a paid version of the Licensed Product, Tthe Licensor shall be liable without limitation for intent and gross negligence;
                    </p>
                </li>                  
                <li>
                    <h3>6.5</h3>
                    <p >In cases of slight liability the Licensor is only liable if he culpably breaches an obligation the performance of which is of particular significance for  the achievement of the purpose of the contract (material duty) In this cases the liability of Licensor is limited to the foreseeable damage typically associated with this type of contract.
                    </p>
                </li>  
                <li>
                    <h3>6.6</h3>
                    <p >The Licensor's liability is further excluded if the damage would not have occurred if the data had been properly backed up. Otherwise, liability for data loss is limited to the typical restoration costs that would have been incurred if data backups had been made regularly and in accordance with the risks involved.
                    </p>
                </li>  
                <li>
                    <h3>6.7</h3>
                    <p >The aforementioned limitations of liability do not apply in cases of injury to life, body or health or in the event of claims under the German Product Liability Act or fraudulent concealment of defects. Liability for warranties assumed or given by the Licensor is also not affected hereby. It is clarified that the Licensor does not make any warrant. 
                    </p>
                </li>  
                <li>
                    <h3>6.8</h3>
                    <p >Where liability is limited, such limitation also applies to the personal liability of Licensor’s employees, representatives, and vicarious agents.
                    </p>
                </li>                                                                                             
            </ul> 

            <h3>7. Assignment and set-off 
            </h3>
            <ul>
                <li>
                    <h3>7.1</h3>
                    <p >Claims against the Licensor may only be transferred to third parties with the written consent of the Licensor.
                    </p>
                </li>
                <li>
                    <h3>7.2</h3>
                    <p >Any set-off of claims is only permissible with claims that are undisputed or have been established by a court of law.
                    </p>
                </li>              
            </ul> 

            <h3>8. Final Provisions
            </h3>
            <ul>
                <li>
                    <h3>8.1</h3>
                    <p >The contractual language shall be English. This Agreement shall be governed by the laws of the Federal Republic of Germany with exclusion of the UN Sales Convention (CISG). For consumers, this only applies to the extent that they do not lose the protection of binding legal provisions of their state of usual residence. 
                    </p>
                </li>
                <li>
                    <h3>8.2</h3>
                    <p >The place of jurisdiction for all disputes arising from this agreement is Herford, Germany, provided that you are a merchant within the meaning of German Commercial Code. The same applies when you have upon the commencement of legal proceedings no general place of jurisdiction in the Federal Republic of Germany.
                    </p>
                </li>         
                <li>
                    <h3>8.3</h3>
                    <p >Contradicting, deviating or supplementing general terms and conditions are not recognized unless the Licensor expressly agrees to their validity in writing
                    </p>
                </li>
                <li>
                    <h3>8.4</h3>
                    <p >Should any provision of this Agreement be or become invalid, this shall not affect the validity of the remaining terms. 
                    </p>
                </li>                       
            </ul>             


          </section>

      </main>


    </div>
  );
};

export default TermsOfService;