import React, { useState, useEffect, useRef } from 'react';
import { Play, Pause } from 'lucide-react';

const AudioPlayer = ({ original, processed, label, composer, url }) => {
  const [isPlayingOriginal, setIsPlayingOriginal] = useState(false);
  const [isPlayingProcessed, setIsPlayingProcessed] = useState(false);
  const [originalProgress, setOriginalProgress] = useState(0);
  const [processedProgress, setProcessedProgress] = useState(0);
  const [isOriginalReady, setIsOriginalReady] = useState(false);
  const [isProcessedReady, setIsProcessedReady] = useState(false);

  const originalRef = useRef(null);
  const processedRef = useRef(null);

  useEffect(() => {
    const setupAudio = (audioRef, setIsReady, source) => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.src = '';
      }

      audioRef.current = new Audio(source);
      
      audioRef.current.load();
      audioRef.current.oncanplaythrough = () => setIsReady(true);
      audioRef.current.onerror = (e) => console.error("Audio loading error:", e);

      return () => {
        if (audioRef.current) {
          audioRef.current.pause();
          audioRef.current.src = '';
          audioRef.current.oncanplaythrough = null;
          audioRef.current.onerror = null;
        }
      };
    };

    const cleanupOriginal = setupAudio(originalRef, setIsOriginalReady, original);
    const cleanupProcessed = setupAudio(processedRef, setIsProcessedReady, processed);

    const updateOriginalProgress = () => {
      setOriginalProgress((originalRef.current.currentTime / originalRef.current.duration) * 100);
    };

    const updateProcessedProgress = () => {
      setProcessedProgress((processedRef.current.currentTime / processedRef.current.duration) * 100);
    };

    originalRef.current.addEventListener('timeupdate', updateOriginalProgress);
    processedRef.current.addEventListener('timeupdate', updateProcessedProgress);
    originalRef.current.addEventListener('ended', () => setIsPlayingOriginal(false));
    processedRef.current.addEventListener('ended', () => setIsPlayingProcessed(false));

    return () => {
      cleanupOriginal();
      cleanupProcessed();
      originalRef.current.removeEventListener('timeupdate', updateOriginalProgress);
      processedRef.current.removeEventListener('timeupdate', updateProcessedProgress);
      originalRef.current.removeEventListener('ended', () => setIsPlayingOriginal(false));
      processedRef.current.removeEventListener('ended', () => setIsPlayingProcessed(false));
    };
  }, [original, processed]);

  const togglePlay = (audioRef, setIsPlaying, isPlaying, isReady) => {
    if (!isReady) {
      console.warn("Audio not ready yet");
      return;
    }

    if (!isPlaying) {
      audioRef.current.play().catch(error => console.error("Playback failed", error));
      setIsPlaying(true);
    } else {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  const seek = (event, audioRef) => {
    const progressBar = event.currentTarget;
    const clickPosition = event.clientX - progressBar.getBoundingClientRect().left;
    const clickPercentage = clickPosition / progressBar.offsetWidth;
    audioRef.current.currentTime = clickPercentage * audioRef.current.duration;
  };

  return (
    <div className="bg-gray-900 p-6 rounded-lg shadow-lg mb-1">
      <p className="text-yellow-400 font-semibold mb-4 w-full">
        {label} &nbsp;&nbsp;(&nbsp; 
        <a 
          href={url} 
          target="_blank" 
          rel="noopener noreferrer"
          className="hover:underline transition-all duration-300"
        >
          {composer}
        </a> )
      </p>
      <div className="space-y-4">
        <div className="flex items-center mb-2">
          <span className="text-white mr-2">Disturia</span>
          <button
            onClick={() => togglePlay(processedRef, setIsPlayingProcessed, isPlayingProcessed, isProcessedReady)}
            className={`p-2 rounded-full mr-2 ${isProcessedReady ? 'bg-yellow-400 text-gray-900 hover:bg-yellow-500' : 'bg-gray-600 text-gray-400 cursor-not-allowed'}`}
            disabled={!isProcessedReady}
          >
            {isPlayingProcessed ? <Pause size={20} /> : <Play size={20} />}
          </button>
          <div 
            className="flex-grow bg-gray-600 h-2 rounded-full cursor-pointer"
            onClick={(e) => seek(e, processedRef)}
          >
            <div 
              className="h-full bg-yellow-400 rounded-full"
              style={{width: `${processedProgress}%`}}
            ></div>
          </div>
        </div>
        <div className="flex items-center mb-2">
          <span className="text-white mr-2">Original</span>
          <button
            onClick={() => togglePlay(originalRef, setIsPlayingOriginal, isPlayingOriginal, isOriginalReady)}
            className={`p-2 rounded-full mr-2 ${isOriginalReady ? 'bg-gray-600 text-white hover:bg-gray-500' : 'bg-gray-800 text-gray-400 cursor-not-allowed'}`}
            disabled={!isOriginalReady}
          >
            {isPlayingOriginal ? <Pause size={20} /> : <Play size={20} />}
          </button>
          <div 
            className="flex-grow bg-gray-600 h-2 rounded-full cursor-pointer"
            onClick={(e) => seek(e, originalRef)}
          >
            <div 
              className="h-full bg-gray-400 rounded-full"
              style={{width: `${originalProgress}%`}}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;