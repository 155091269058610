import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (

    <header className="bg-gray-700 py-4 px-8">
    <div className="max-w-6xl mx-auto flex justify-between items-center">
      <div className="text-3xl font-bold text-yellow-400">
        <Link to="/" className="text-white hover:text-yellow-400 transition-colors">FiniteAudio</Link>
      </div>
    </div>
  </header>

  );
};

export default Header;