import React from 'react';
import { Download } from 'lucide-react';
import Header from './Header';
import styles from './PrivacyPolicy.module.css';

const DownloadDisturia = () => {
  const version = "1.0";
  const windowsSize = "4 MB";
  const macSize = "8 MB";

  const handleDownload = (platform, url) => {
    console.log(`Download initiated for ${platform}`);
    window.location.href = url;
  };

  // Inline style to override the margin-top
  const reducedMarginStyle = { marginTop: '0.5rem' };

  return (
    <div className="min-h-screen bg-gray-100">
      <Header/>
      <main className={`max-w-5xl mx-auto px-4 ${styles.container}`}>
        <section id="download-disturia" className="bg-gray-100 py-12">
          <div className="max-w-5xl mx-auto px-8">
            <h2 className="text-4xl font-bold mb-12 text-center text-gray-800">Download Disturia v{version}</h2>
            <div className="grid md:grid-cols-2 gap-16">
              <div className="bg-white rounded-lg shadow-md flex flex-col items-center text-center">
                <h3 className="text-2xl font-semibold text-gray-800 mt-4 mb-2">Windows</h3>
                <p className="text-xl text-gray-800">Windows 10+</p>             
                <p className="text-gray-600 mb-2" style={reducedMarginStyle}>VST2 64-bit, VST3 64-bit</p>
                <p className="text-sm text-gray-500 mb-4">File size: {windowsSize}</p>
                <button 
                  onClick={() => handleDownload('Windows', 'https://www.finiteaudio.com/downloads/disturia/Disturia_Setup.exe')}
                  className="bg-blue-400 hover:bg-blue-500 text-white font-bold py-2 px-4 rounded flex items-center mb-6"
                >
                  <Download className="mr-2 h-4 w-4" /> Download for Windows
                </button>
              </div>
              <div className="bg-white rounded-lg shadow-md flex flex-col items-center text-center">
                <h3 className="text-2xl font-semibold text-gray-800 mt-4 mb-2">Mac</h3>
                <p className="text-xl text-gray-800">macOS 10.14+, Intel and Apple Silicon</p>
                <p className="text-gray-600 mb-2" style={reducedMarginStyle}>AU 64-bit, VST2 64-bit, VST3 64-bit</p>
                <p className="text-sm text-gray-500 mb-4">File size: {macSize}</p>
                <button 
                  onClick={() => handleDownload('Mac', 'https://www.finiteaudio.com/downloads/disturia/Disturia.pkg')}
                  className="bg-blue-400 hover:bg-blue-500 text-white font-bold py-2 px-4 rounded flex items-center mb-6"
                >
                  <Download className="mr-2 h-4 w-4" /> Download for Mac
                </button>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default DownloadDisturia;