import React from 'react';
import { Link } from 'react-router-dom';
import { Mail } from 'lucide-react';
import Header from './Header';
import Footer from './Footer';
import styles from './PrivacyPolicy.module.css';

const RefundPolicy = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      <Header/>
      <main className={`max-w-5xl mx-auto px-4 py-8 ${styles.container}`}>
        <section className="bg-white shadow-md rounded-lg p-6 mb-6">

          <h2>Refund Policy</h2>

    		  <p>Finite Audio offers a 14-day money-back guarantee on all our products. 
            This period begins from the moment you initiate your download. 
            If you're unsatisfied with your purchase, you can request a refund within this timeframe by reaching out to our customer service team at support@finiteaudio.com. 
            Upon approval, we'll process the refund to your original payment method. 
            Please note that the actual appearance of the refund in your account may vary depending on your financial institution's processing times. 
            For a comprehensive understanding of your right of withdrawal, please refer to the correct legal terms provided below.
          </p>  
          <p>NOTE: Our order process is conducted by our online reseller FastSpring.com. FastSpring.com is the Merchant of Record for all our orders.
            Therefore the below official legal terms refer to FastSpring as the contact. 
          </p>

          <h3>1. Right of Withdrawal</h3>

          <ul>
            <li>
                <h3>1.1</h3>
                <p >As a consumer, you have the following right of withdrawal when purchasing the Licensed Product: </p>
                <h3>Instruction on the right of withdrawal </h3>
                <p >You have the right to withdraw from the contract within fourteen days without giving any reason. 
                    The withdrawal period is fourteen days from the day on which you or a third party named by you, who is not the carrier, took possession of the last goods.
                    To exercise the right to cancel, you must inform BRIGHT MARKET, LLC D/B/A FASTSPRING, 801 GARDEN ST. #201, SANTA BARBARA, CA 93101 of your decision to cancel this contract by a clear statement (e.g. a letter sent by post, fax or e-mail). You can use the attached sample withdrawal form, but this is not mandatory.
                    To meet the withdrawal deadline, it is sufficient for you to send your notification of exercising your right of withdrawal before the withdrawal period has expired.
                </p>

                <h3>Consequences of revocation</h3>
                <p >If you withdraw from this contract, all payments received shall be reimbursed, including the costs of delivery (with the exception of the supplementary costs resulting from your choice of a type of delivery other than the least expensive type of standard delivery offered by us), without undue delay and in any event not later than fourteen days from the day on which BRIGHT MARKET, LLC D/B/A FASTSPRING  was informed about your decision to withdraw from this contract. For this repayment, the same means of payment that you used for the original transaction will be used, unless expressly agreed otherwise with you; under no circumstances will you be charged any fees for this repayment. The refund may be withhold until BRIGHT MARKET, LLC D/B/A FASTSPRING has received the goods back or until you have provided proof that you have returned the goods, whichever is the earliest.</p>                
                <p>If you have requested that the services should commence during the withdrawal period, you shall pay a reasonable amount corresponding to the proportion of the services already provided up to the time at which you inform BRIGHT MARKET, LLC D/B/A FASTSPRING of the exercise of the right of withdrawal with regard to this contract compared to the total scope of the services provided for in the contract.</p>

                <h3>Expiration of the right of withdrawal</h3>
                <p >In the case of a contract for the provision of services, the right of withdrawal shall also expire if the service has been provided in full after you have given your express consent to this and you have simultaneously confirmed your knowledge that you will lose your right of revocation upon complete fulfilment of the contract.</p>
                <p >In the case of a contract for the delivery of digital content that is not on a physical data carrier, the right of withdrawal also expires if the execution of the contract started after you have given your express consent and you have simultaneously confirmed your knowledge that you lose your right of revocation upon commencement of the execution of the contract.</p>

                <h3>Sample withdrawal form</h3>
                <p>
                (If you wish to withdraw from the contract, please complete this form and return it to BRIGHT MARKET, LLC D/B/A FASTSPRING.).<br></br><br></br>
		-to ##, address, fax: ##, email: ##:<br></br>
		<br></br>
		____________________________<br></br>
		I/we (* ) hereby revoke the contract concluded by me/us (* ) for the purchase of the following goods (* )/the provision of the following service ( )*<br></br>
		<br></br>
		____________________________<br></br>
		-Ordered on (* )/received on ( )*<br></br>
		<br></br>
		____________________________<br></br>
		-Name of the consumer(s)<br></br>
		<br></br>
		____________________________<br></br>
		-Address of the consumer(s)<br></br>
		<br></br>
		____________________________<br></br>
		-Signature of the consumer(s) (only for notification on paper)<br></br>
		<br></br>
		____________________________<br></br>
		-Date<br></br>
		<br></br>
		____________________________<br></br>
	(* ) Delete as appropriate.<br></br>




                </p>




            </li>   
          </ul>       

                   

        </section>

      </main>



    </div>
  );
};

export default RefundPolicy;