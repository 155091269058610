import React from 'react';
import Header from './Header';
import Footer from './Footer';
import styles from './PrivacyPolicy.module.css';

const Impressum = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      <Header/>
      <main className={`max-w-5xl mx-auto px-4 py-8 ${styles.container}`}>
        <section className="bg-white shadow-md rounded-lg p-6 mb-6">

		<h2>Legal Notice - Impressum</h2>

          <h4>Information in accordance with &sect; 5 TMG<br></br>
		  Angaben gem&auml;&szlig; &sect; 5 TMG </h4>
					<p>Finite Audio UG (haftungsbeschr&auml;nkt)<br></br>
          Auf dem Eichelkamp 27<br></br>
          32052 Herford<br></br>
          Germany
          </p>

          <h4>Managing Director<br></br>
		  Gesch&auml;ftsf&uuml;hrer</h4>
		  <p >Martin Schuppius </p>          

          <h4>Head office and registration court<br></br>
		  Sitz und Registergericht</h4>
		  <p>Bad Oeynhausen HRB 18423 </p>

		  <h4>Contact<br></br>
		  Kontakt</h4>
		  <img 
            src="/img/Kontakt.png" 
            alt="Finite Audio UG Office" 
            className={styles.kontaktImage}
          />  
{/*
			<p >+49 (0) 17631180117 <br></br>
				martin@finiteaudio.com
			</p>
*/}

		  <h4>Responsible for the content in accordance with &sect; 55 Abs. 2 RStV <br></br>
		  Verantwortlich f&uuml;r den Inhalt nach &sect; 55 Abs. 2 RStV</h4>
		  <p>Finite Audio UG (haftungsbeschr&auml;nkt)<br></br>
             Auf dem Eichelkamp 27<br></br>
             32052 Herford<br></br>
             Germany
          </p>     


		  <h2><br></br>Disclaimer</h2>
			<h3>Liability for content</h3>
			<p >As service provider and according to statutory provisions we are responsible for our own content on these web pages,
				in accordance with &sect; 7 section 1 of the Telemedia Act (TMG). In accordance with &sect;&sect; 8 to 10 TMG we as
				service provider are not obliged to monitor the transmitted or saved information of third parties, or investigate circumstances
				pointing to illegal activity.</p>
			<p >Our obligations to remove or block the use of information under generally applicable laws remain unaffected by this
				as per &sect;&sect; 8 to 10 of the TMG. A liability in this regard, however, is only possible from the date of knowledge
				of a specific infringement. Upon notification of violations, we will remove the content immediately.</p>
			<h3>Liability for links</h3>
			<p >Our site contains links to third party websites, on whose contents we have no influence. Therefore, we can not assume
				any liability for these external contents. Responsibility for the content of external links (to web pages of third
				parties) lies solely with the operators of the linked pages. The linked pages were checked for possible legal violations
				at the time of linking. No violations were evident to us at the time of linking.</p>
			<p >However, a permanent content control of the linked pages is not reasonable without specific evidence of an infringement.
				Should any legal infringement become known to us, we will remove the respective link immediately.</p>
			<h3>Copyright</h3>
			<p >Our web pages and their contents are subject to German copyright law. Unless expressly permitted by law, every form
				of utilizing, reproducing or processing works subject to copyright protection on our web pages requires the prior consent
				of the respective owner of the rights. Individual reproductions of a work are only allowed for private use. The materials
				from these pages are copyrighted and any unauthorized use may violate copyright laws. </p>  

			<h2><br></br>Haftungsausschluss</h2>
				<h3>Haftung f&uuml;r Inhalte</h3>
				<p >Als Diensteanbieter sind wir gem&auml;&szlig; &sect; 7 Abs.1 TMG f&uuml;r eigene Inhalte auf diesen Seiten nach den
					allgemeinen Gesetzen verantwortlich. Nach &sect;&sect; 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet,
					&uuml;bermittelte oder gespeicherte fremde Informationen zu &uuml;berwachen oder nach Umst&auml;nden zu forschen, die
					auf eine rechtswidrige T&auml;tigkeit hinweisen.</p>
				<p >Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon
					unber&uuml;hrt. Eine diesbez&uuml;gliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung
					m&ouml;glich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>
				<h3>Haftung f&uuml;r Links</h3>
				<p >Unser Angebot enth&auml;lt Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb
					k&ouml;nnen wir f&uuml;r diese fremden Inhalte auch keine Gew&auml;hr &uuml;bernehmen. F&uuml;r die Inhalte der verlinkten
					Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum
					Zeitpunkt der Verlinkung auf m&ouml;gliche Rechtsverst&ouml;&szlig;e &uuml;berpr&uuml;ft. Rechtswidrige Inhalte waren
					zum Zeitpunkt der Verlinkung nicht erkennbar.</p>
				<p >Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung
					nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p>
				<h3>Urheberrecht</h3>
				<p >Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht.
					Die Vervielf&auml;ltigung, Bearbeitung, Verbreitung und jede Art der Verwertung au&szlig;erhalb der Grenzen des Urheberrechtes
					bed&uuml;rfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
					sind nur f&uuml;r den privaten, nicht kommerziellen Gebrauch gestattet.</p>
				<p >Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet.
					Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung
					aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir
					derartige Inhalte umgehend entfernen.</p>
            

        </section>

      </main>

    </div>
  );
};

export default Impressum;