import React, { useEffect } from 'react';

const FastSpringScript = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'fsc-api';
    script.src = 'https://sbl.onfastspring.com/sbl/1.0.1/fastspring-builder.min.js';
    script.type = 'text/javascript';
    script.setAttribute('data-storefront', 'finiteaudio.onfastspring.com/popup-finiteaudio');
    
    script.onload = () => {
      console.log('FastSpring script loaded');
      if (window.fastspring) {
        console.log('FastSpring object is available');
        window.fastspring.builder.reset();
      } else {
        console.error('FastSpring object is not available');
      }
    };

    script.onerror = () => {
      console.error('Failed to load FastSpring script');
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null;
};

export default FastSpringScript;